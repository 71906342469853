<template>

<app-content :loading="is.loading">

	<app-content-head :title="title" :back="$route.meta.back" :readonly="$authorised('con/schedule/access', 'read')">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body>

		<app-content-box :readonly="$authorised('con/schedule/access', 'read')">

			<app-input-text label="Name" placeholder="Enter name..." v-model="model.name" :validation="$v.model.name" />
			<app-input-text label="Description" placeholder="Enter description..." v-model="model.description" :validation="$v.model.description" />
			<app-input-text label="Quantity" placeholder="Enter quantity..." v-model="model.quantity" :validation="$v.model.quantity" />
			<app-input-image label="Image" placeholder="Enter image..." v-model="model.image" :validation="$v.model.image" />

		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			readonly: this.$authorised('con/schedule/access', 'read'),
			model: {
				id: false,
				name: '',
				description: '',
				quantity: 1,
				image: ''
			}
		}

	},

	validations: {
		model: {
			name: {
				required
			},
			description: {

			},
			quantity: {

			},
			image: {

			}
		}
	}

}

</script>

<style scoped>

</style>